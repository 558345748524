import React from "react"
// import PropTypes from "prop-types"
import { format } from "date-fns"

import ArticleLayout from "../layouts/articleLayout"
import SEO from "../components/seo"

const ArticleTemplate = ({ pageContext: { node } }) => {
  const { title, date } = node.childMarkdownRemark.frontmatter
  const { html } = node.childMarkdownRemark

  return (
    <ArticleLayout>
      <SEO title={title} />
      <h1 className="entry-title">{title}</h1>
      <p className="meta">
        <strong>{format(new Date(date), `MMMM do, yyyy`)}</strong>
      </p>
      <div className="u-mb+" dangerouslySetInnerHTML={{ __html: html }} />
    </ArticleLayout>
  )
}

// ArticleTemplate.propTypes = {
//   pageContext: PropTypes.object.isRequired,
// }

export default ArticleTemplate
