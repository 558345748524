/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Nav from "../components/nav"
// import { useStaticQuery, graphql } from "gatsby"

// import "../components/layout.css"
// import Nav from '../components/nav';

const ArticleTemplate = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title,
  //         subTitle,
  //         author
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <Nav />
      <div className="container">
        <div className="row">
          <div className="columns medium-12 large-6 large-centered">
            <article>
              {/* <main> */}
              {children}
              {/* </main> */}
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

ArticleTemplate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ArticleTemplate
